<template>
  <div class="eui-o-signPDFViewer">
    <EuiPDFViewer :pdf="pdf"
               :title="title"
               :currentPage="currentPage"
               @document-rendered="onDocumentRendered"
               @scale-change="updateScale"
               @page-count="updatePageCount"
               @page-focus="updateCurrentPage"
               @update:currentPage="updateCurrentPage"
               base64
               preview
               pagination
               :buffer="false">
      <template #header>
        <EuiButton iconOnly size="large" color="primary" @click="$emit('download')"><EuiIcon name="download"/></EuiButton>
      </template>
      <template v-for="index in pageCount" v-slot:[`zone-${index}`]>
        <PDFZoneSign v-for="(item, zoneIndex) in signatureZones.filter(item => (item.zone.position.page === index) && !item.zone.isInvisibleSignature)"
             :id="item.zone.id"
             :key="`zone-sign-${zoneIndex}`"
             :page.sync="item.zone.position.page"
             :pageCount="pageCount"
             :scale.sync="scale"
             :viewport="getViewport(index)"
             :pagePoint.sync="item.zone.pagePoint"
             draggable="false">
          {{ item.zone.user.fullname }}
        </PDFZoneSign>
        <PDFZoneText v-for="(item, zoneIndex) in textZones.filter(zone => zone.zone.position.page === index)"
                     :id="item.zone.id"
                     :ref="`zone-${item.zone.id}`"
                     :class="`zone-${item.zone.id}`"
                     :key="`zone-text-${zoneIndex}`"
                     :page.sync="item.zone.position.page"
                     :pageCount="pageCount"
                     :scale.sync="scale"
                     :viewport="getViewport(index)"
                     :pagePoint.sync="item.zone.pagePoint"
                     draggable="false">
          <EuiTextarea noResize
                       class="eui-o-signPDFViewer__textarea"
                       :style="textStyleObject"
                       :id="`anchor-${item.zone.id}`"
                       :placeholder="item.zone.name"
                       v-model="textsModel.find(model => model.id === item.zone.id).model"/>
        </PDFZoneText>
        <template v-if="paraphZones.length > 0">
          <PDFBand :key="`band-${index}`"
                   class="eui-o-signPDFViewer__band"
                   :zones.sync="bandZones"
                   :align="bandAlign"
                   :id="paraphZones[0].zone.id"
                   :scale.sync="scale"
                   :viewport="getViewport(index)">
          </PDFBand>
        </template>
      </template>
    </EuiPDFViewer>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import EuiPDFViewer from '@silae/edoc-ui/src/components/organisms/PDFViewer/PDFViewer';
import PDFZoneSign from './_internal/zones/PDFZoneSign';
import PDFZoneText from './_internal/zones/PDFZoneText';
import PDFBand from './_internal/zones/PDFBand';

import {
  PIXEL_RATIO,
} from '@silae/edoc-ui/src/components/organisms/PDFViewer/utils/constants';

export default {
  name: 'SignPDFViewer',
  components: { EuiPDFViewer, PDFZoneSign, PDFBand, PDFZoneText },
  data() {
    return {
      errors: [],
      scale: undefined,
      currentZone: undefined,
      pageCount: undefined,
      isPreviewEnabled: false,
      pages: undefined,
      showPopover: false,
      popoverContent: 0,
      position: {
        x: 0,
        y: 0,
        page: 1,
      },
      bandAlign: 'right',
    };
  },
  props: {
    textsModel: {
      type: Array,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    id: {
      type: String,
      required: true,
    },
    pdf: {
      type: String,
      required: true,
    },
    suggestions: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
    },
    zones: {
      type: Array,
      default: () => [],
    },
    base64: {
      type: Boolean,
      default: false,
    },
    scrollToZone: {
      type: [Number, String],
    },
  },
  watch: {
    viewport: {
      handler(newValue, oldValue) {
        if (oldValue === null && newValue !== null) {
          this.error = [];
          for (let i = 0; i < this.zones.length; i++) {
            if (this.zones[i].zone.position.docId === this.id) {
              if (this.zones[i].zone.position.page > this.pageCount) {
                this.errors.push({
                  message: `La position pour l'utilisateur ${this.zones[i].zone.user.fullname} en page ${this.zones[i].zone.user.page - 1} est en erreur`,
                });
                this.zones[i].zone.position.page = this.pageCount;
              }
            }
          }
          if (this.errors.length > 0) {
            this.$emit('errors', this.errors);
            this.error = [];
          }
        }
      },
    },
    scrollToZone(value) {
      if (value) {
        this.scrollToClass(`zone-${value}`);
        this.$emit('update:scrollToZone', undefined);
      }
    },
  },
  computed: {
    textStyleObject() {
      return {
        'font-size': `${Number(this.scale * 12 / PIXEL_RATIO)}px`,
      };
    },
    paraphZones() {
      return this.zones.filter(item => (item.zone && item.zone.position.docId === this.id && item.zone.zoneType === 'paraph'));
    },
    signatureZones() {
      return this.zones.filter(item => (item.zone && item.zone.position.docId === this.id && item.zone.zoneType === 'signature' && item.zone.position.viewport));
    },
    textZones() {
      return this.zones.filter(item => (item.zone && item.zone.position.docId === this.id && item.zone.zoneType === 'textfield' && item.zone.position.viewport));
    },
    viewport() {
      return this.getViewport(this.currentPage);
    },
    signAttrs() {
      return { style: `position: absolute; left: ${this.position.x}px; top: ${this.position.y}px;` };
    },
    signAnchor() {
      return { style: `position: absolute; left: ${this.position.x - 4}px; top: ${this.position.y - 4}px;` };
    },
    bandZones() {
      return this.paraphZones.filter((zone) => zone.zone.position.docId === this.id);
    },
  },
  methods: {
    ...mapActions({
      searchFieldOnPDF: 'ModuleEdocSign/parapheurs/searchFieldOnPDF',
    }),
    getViewport(page, scale = this.scale) {
      if (page && this.pages && this.pages[page - 1] && scale) {
        let viewport = this.pages[page - 1].getViewport({ scale: scale });
        if (viewport.rotation === 90 || viewport.rotation === 270) {
          viewport = viewport.clone({ rotation: 0 });
          const width = viewport.width;
          viewport.width = viewport.height;
          viewport.height = width;
          const xMax = viewport.viewBox[3];
          const yMax = viewport.viewBox[2];
          viewport.viewBox[2] = xMax;
          viewport.viewBox[3] = yMax;
          viewport.transform[5] = viewport.height;
        }
        if (viewport.rotation !== 0) {
          viewport = viewport.clone({ rotation: 0 });
        }
        return viewport;
      }
      return null;
    },
    scrollToClass(className) {
      const el = this.$el.getElementsByClassName(className)[0];
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
    },
    onDocumentRendered(pages) {
      this.pages = pages;
      this.$emit('document-rendered', pages);
    },
    updatePageCount(pageCount) {
      this.pageCount = pageCount;
      this.$emit('page-count', pageCount);
    },
    updateCurrentPage(currentPage) {
      this.$emit('update:currentPage', currentPage);
    },
    convertToViewportPosition(viewport, x, y, width, height) {
      const bottomLeftPoint = viewport.convertToViewportPoint(x, y + height);
      const topRightPoint = viewport.convertToViewportPoint(x + width, y);
      return {
        x: bottomLeftPoint[0],
        y: bottomLeftPoint[1],
        height: topRightPoint[1] - bottomLeftPoint[1],
        width: topRightPoint[0] - bottomLeftPoint[0],
      };
    },
    setPosition(scale = this.scale) {
      for (let i = 0; i < this.zones.length; i++) {
        if (this.zones[i].zone.pagePoint === undefined &&
          (this.zones[i].zone.position.x !== undefined &&
            this.zones[i].zone.position.y !== undefined) &&
          this.zones[i].zone.position.docId === this.id) {
          if (this.zones[i].zone.zoneType !== 'paraph') {
            const viewport = this.getViewport(this.zones[i].zone.position.page, scale);
            const { x, y, width, height } = this.convertToViewportPosition(
              viewport,
              this.zones[i].zone.position.x,
              this.zones[i].zone.position.y,
              this.zones[i].zone.position.width,
              this.zones[i].zone.position.height);

            this.zones[i].zone.position.x = x / PIXEL_RATIO;
            this.zones[i].zone.position.y = y / PIXEL_RATIO;
            this.zones[i].zone.position.height = height / PIXEL_RATIO;
            this.zones[i].zone.position.width = width / PIXEL_RATIO;
            this.$set(this.zones[i].zone.position, 'viewport', viewport);
            this.$set(this.zones[i].zone, 'pagePoint', this.getPagePoint(
              this.zones[i].zone.position.x,
              this.zones[i].zone.position.y,
              this.zones[i].zone.position.width,
              this.zones[i].zone.position.height,
              viewport));
          }
        }
      }
    },
    updateScale($event) {
      this.setPosition($event);
      this.scale = $event;
    },
    getPagePoint(x, y, width, height, viewport = this.viewport) {
      if (viewport) {
        return viewport.convertToPdfPoint(x, y)
          .concat(viewport.convertToPdfPoint(x + width, y + height));
      }
    },
  },
  created() {
    // PDFPageProxy#getViewport
    // https://mozilla.github.io/pdf.js/api/draft/PDFPageProxy.html
    // this.viewport = this.pages[this.currentPage - 1].getViewport({ scale: this.scale });
  },
};
</script>

<style lang="scss">
.eui-o-signPDFViewer__zone {
  border-style: solid;
  cursor: initial;
}
</style>
