<template>
  <svg class="eui-o-advancedPDFViewer__icon"
       :class="`-${color}`"
       width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.46485 11.1558C2.27384 11.1558 2.92971 10.5 2.92971 9.69099V2.92971H9.69099C10.5 2.92971 11.1558 2.27384 11.1558 1.46485C11.1558 0.655864 10.5 0 9.69099 0H1.46485C0.655864 0 0 0.655864 0 1.46485V9.69099C0 10.5001 0.655864 11.1558 1.46485 11.1558ZM48.5356 0H40.3095C39.5005 0 38.8446 0.655864 38.8446 1.46485C38.8446 2.27384 39.5005 2.92971 40.3095 2.92971H47.0708V9.69099C47.0708 10.5 47.7266 11.1558 48.5356 11.1558C49.3446 11.1558 50.0005 10.5 50.0005 9.69099V1.46485C50.0005 0.655864 49.3446 0 48.5356 0ZM47.0708 40.3095C47.0708 39.5005 47.7266 38.8446 48.5356 38.8446C49.3446 38.8446 50.0005 39.5004 50.0005 40.3095V48.5356C50.0005 49.3446 49.3446 50.0005 48.5356 50.0005H40.3095C39.5005 50.0005 38.8446 49.3446 38.8446 48.5356C38.8446 47.7266 39.5005 47.0708 40.3095 47.0708H47.0708V40.3095ZM9.69099 47.0708H2.92971V40.3095C2.92971 39.5005 2.27384 38.8446 1.46485 38.8446C0.655864 38.8446 0 39.5005 0 40.3095V48.5356C0 49.3446 0.655864 50.0005 1.46485 50.0005H9.69099C10.5 50.0005 11.1558 49.3446 11.1558 48.5356C11.1558 47.7266 10.5001 47.0708 9.69099 47.0708ZM30.9826 9L26.2857 13.2971L34.6977 21.2857L39.1429 16.7426L30.9826 9ZM14.3568 21.0114C14.3568 21.0114 11.1504 33.5797 10 38.4948L16.1586 30.0839C15.5995 28.8426 15.9366 26.9596 16.9148 25.8923C18.2281 24.4607 20.5663 24.4607 22.0019 25.7712C23.4376 27.0818 23.5361 29.3054 22.2229 30.7371C21.2281 31.8271 19.3878 32.266 18.085 31.7805L10.3911 39L26.6212 32.7277L33.1429 22.9762L24.6067 15L14.3568 21.0114Z"/>
  </svg>
</template>

<script>
export default {
  name: 'SignIcon',
  props: {
    color: { type: String, default: 'grey' },
  },
};
</script>
