<template>
  <div class="eui-o-signPDFViewer__zoneText"
       :style="styleObject"
       ref="zone">
      <slot/>
  </div>
</template>

<script>
import {
  PIXEL_RATIO,
  ZONE_TEXT_MIN_HEIGHT,
  ZONE_TEXT_MIN_WIDTH,
} from '@silae/edoc-ui/src/components/organisms/PDFViewer/utils/constants';

export default {
  name: 'PDFZoneText',
  watch: {
    scale() {
      this.setSize();
    },
    pagePoint(value) {
      if (value) {
        this.setSize();
      }
    },
  },
  computed: {
    actualSizeViewport() {
      return this.viewport.clone({scale: this.scale});
    },
    pixelSize() {
      const { width: actualSizeWidth, height: actualSizeHeight } = this.viewport;
      const [pixelWidth, pixelHeight] = [actualSizeWidth, actualSizeHeight]
        .map(dim => Math.ceil(dim / PIXEL_RATIO));
      return { width: pixelWidth, height: pixelHeight };
    },
    styleObject() {
      return {
        left: `${this.x}px`,
        top: `${this.y}px`,
        width: typeof this.width === 'number' ? `${this.width}px` : this.width,
        height: typeof this.height === 'number' ? `${this.height}px` : this.height,
      };
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    position: {
      type: Object,
    },
    pagePoint: {
      type: Array,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    viewport: {
      type: Object,
      required: true,
    },
    scale: {
      type: Number,
      default: 1,
      required: true,
    },
    page: {
      type: Number,
      default: 1,
      required: true,
    },
  },
  data() {
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      resizeOriginal: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
    };
  },
  methods: {
    setSize() {
      const rect = this.actualSizeViewport.convertToViewportRectangle(this.pagePoint);
      this.width = Math.abs(Math.round(rect[2] - rect[0]));
      this.height = Math.abs(Math.round(rect[3] - rect[1]));
      this.x = Math.round(rect[0]);
      this.y = Math.round(rect[1]);
    },
    resize(evt) {
      evt.preventDefault();
      const width = (evt.clientX - this.resizeOriginal.x) + this.resizeOriginal.width;
      const height = (evt.clientY - this.resizeOriginal.y) + this.resizeOriginal.height;
      if (width > ZONE_TEXT_MIN_WIDTH && (width + this.x) <= this.pixelSize.width) {
        this.width = width;
      }
      if (height > ZONE_TEXT_MIN_HEIGHT && (height + this.y) <= this.pixelSize.height) {
        this.height = height;
      }
      this.$emit('resize', { id: this.id, width: this.width, height: this.height });
      this.$emit('update:pagePoint', this.actualSizeViewport.convertToPdfPoint(this.x, this.y)
        .concat(this.actualSizeViewport.convertToPdfPoint(this.x + this.width, this.y + this.height)));
    },
  },
  mounted() {
    this.setSize();
  },
};
</script>
