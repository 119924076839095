<template>
  <!--
  dragging: should be available for the order within the band
  resize: no resize for zones within the band
  -->
  <div class="eui-o-signPDFViewer__zone eui-u-flex -initialZone"
       ref="zone"
       :style="styleObject">
    <div v-if="$slots.default" class="eui-o-signPDFViewer__text">
      <slot/>
    </div>
  </div>
</template>

<script>
import {
  PIXEL_RATIO,
  ZONE_INITIALS_TRIGGER_HEIGHT,
  ZONE_INITIALS_TRIGGER_WIDTH,
} from '@silae/edoc-ui/src/components/organisms/PDFViewer/utils/constants';

export default {
  name: 'PDFZoneInitials',
  props: {
    percentX: {
      type: Number,
      default: undefined,
    },
    percentY: {
      type: Number,
      default: undefined,
    },
    scale: {
      type: Number,
      default: 1,
    },
    page: {
      type: Number,
      default: 1,
    },
    viewport: {
      type: Object,
      required: true,
    },
  },
  watch: {
    scale() {
      this.setSize();
    },
  },
  data() {
    return {
      id: null,
      y: 0,
      x: 0,
      width: 80,
      height: 25,
    };
  },
  computed: {
    actualSizeViewport() {
      return this.viewport.clone({scale: this.scale});
    },
    styleObject() {
      return {
        position: 'absolute',
        bottom: 0,
        left: this.x * 100 + '%',
        height: typeof this.height === 'number' ? `${this.height}px` : this.height,
        width: typeof this.width === 'number' ? `${this.width}px` : this.width,
      };
    },
    shouldDisplayDrag() {
      return this.height > ZONE_INITIALS_TRIGGER_HEIGHT && this.width > ZONE_INITIALS_TRIGGER_WIDTH;
    },
    actionsAnchorStyleObject() {
      return {
        'scale': `min(${(this.scale / PIXEL_RATIO) * 1.1}, 1)`,
      };
    },
  },
  methods: {
    setSize() {
      this.height = (25 * this.scale) / PIXEL_RATIO;
      this.width = (40 * this.scale) / PIXEL_RATIO;
    },
  },
  mounted() {
    this.id = this._uid;
    if (this.percentX) this.x = this.percentX;
    if (this.percentY) this.y = this.percentY;
    this.setSize();
  },
};
</script>
