<template>
  <div class="eui-o-signPDFViewer__zone"
       :style="styleObject"
       ref="zone">
    <div class="eui-o-signPDFViewer__icone">
      <SignIcon color="primary" :style="iconStyleObject"/>
    </div>
    <div v-if="$slots.default" class="eui-o-signPDFViewer__text">
      <slot/>
    </div>
  </div>
</template>

<script>
import SignIcon from '../svg/SignIcon';
import { PIXEL_RATIO, ZONE_MIN_HEIGHT, ZONE_MIN_WIDTH } from '@silae/edoc-ui/src/components/organisms/PDFViewer/utils/constants';

export default {
  name: 'PDFZoneSign',
  components: {
    SignIcon,
  },
  watch: {
    scale() {
      this.setSize();
    },
    pagePoint() {
      this.setSize();
    },
  },
  computed: {
    actualSizeViewport() {
      return this.viewport.clone({scale: this.scale});
    },
    styleObject() {
      return {
        left: `${this.x}px`,
        top: `${this.y}px`,
        width: typeof this.width === 'number' ? `${this.width}px` : this.width,
        height: typeof this.height === 'number' ? `${this.height}px` : this.height,
      };
    },
    pixelSize() {
      const {width: actualSizeWidth, height: actualSizeHeight} = this.actualSizeViewport;
      const [pixelWidth, pixelHeight] = [actualSizeWidth, actualSizeHeight]
        .map(dim => Math.ceil(dim / PIXEL_RATIO));
      return { width: pixelWidth, height: pixelHeight };
    },
    iconStyleObject() {
      return {
        height: `${this.height / 1.5}px`,
        width: `${this.height / 1.5}px`,
      };
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    position: {
      type: Object,
    },
    pagePoint: {
      type: Array,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    viewport: {
      type: Object,
      required: true,
    },
    scale: {
      type: Number,
      default: 1,
      required: true,
    },
    page: {
      type: Number,
      default: 1,
      required: true,
    },
  },
  data() {
    return {
      dragging: false,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      resizeOriginal: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
      },
    };
  },
  methods: {
    startDrag(evt) {
      evt.dataTransfer.dropEffect = 'move';
      evt.dataTransfer.effectAllowed = 'move';
      evt.dataTransfer.setData('itemID', this.id);
      evt.dataTransfer.setData('zoneType', 'signature');
      const rect = evt.target.getBoundingClientRect();
      const offsetX = evt.clientX - rect.left;
      const offsetY = evt.clientY - rect.top;
      evt.dataTransfer.setData('offsetX', `${offsetX}`);
      evt.dataTransfer.setData('offsetY', `${offsetY}`);
    },
    handleResize(evt) {
      evt.preventDefault();
      this.resizeOriginal.x = evt.clientX;
      this.resizeOriginal.y = evt.clientY;
      this.resizeOriginal.width = this.width;
      this.resizeOriginal.height = this.height;
      document.onmouseup = this.reset;
      document.onmousemove = this.resize;
    },
    setSize() {
      const rect = this.actualSizeViewport.convertToViewportRectangle(this.pagePoint);
      this.width = Math.abs(Math.round(rect[2] - rect[0]));
      this.height = Math.abs(Math.round(rect[3] - rect[1]));
      this.x = Math.round(rect[0]);
      this.y = Math.round(rect[1]);
    },
    resize(evt) {
      evt.preventDefault();
      const width = (evt.clientX - this.resizeOriginal.x) + this.resizeOriginal.width;
      const height = (evt.clientY - this.resizeOriginal.y) + this.resizeOriginal.height;
      if (width > ZONE_MIN_WIDTH && (width + this.x) <= this.pixelSize.width) {
        this.width = width;
      }
      if (height > ZONE_MIN_HEIGHT && (height + this.y) <= this.pixelSize.height) {
        this.height = height;
      }
      this.$emit('resize', { id: this.id, width: this.width, height: this.height });
      this.$emit('update:pagePoint', this.actualSizeViewport.convertToPdfPoint(this.x, this.y)
        .concat(this.actualSizeViewport.convertToPdfPoint(this.x + this.width, this.y + this.height)));
    },
    reset() {
      this.dragging = false;
      document.onmouseup = null;
      document.onmousemove = null;
    },
  },
  mounted() {
    this.setSize();
  },
};
</script>
