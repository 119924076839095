<template>
  <div class="eui-o-signPDFViewer__band"
       :style="styleObject"
       ref="band">
    <div class="eui-o-signPDFViewer__paraphZone">
      <EuiList>
        <PDFZoneInitials v-for="(zone, zoneIndex) in paraphZones"
                         :key="`zone-${zoneIndex}`"
                         :scale="scale"
                         :percentX="zone.zone.position.x"
                         :percentY="zone.zone.position.y"
                         :viewport="viewport"
                         :ref="'userParaph'+zoneIndex"
                         @mousedown="$event.preventDefault()">
          {{ getInitials(zone.zone.user.fullname) }}
        </PDFZoneInitials>
      </EuiList>
    </div>
  </div>
</template>

<script>
import PDFZoneInitials from './PDFZoneInitials';
import initials from '../../../../utils/initials';

export default {
  name: 'PDFBand',
  components: { PDFZoneInitials },
  data() {
    return {
      dragging: false,
      sortedZones: [],
      startPosition: 0,
      endPosition: 0,
      previousPosition: 0,
      height: 40,
      pdfTop: 0,
    };
  },
  watch: {
    scale() {
      this.setSize();
    },
    pagePoint() {
      this.setSize();
    },
    zones: {
      handler(val) {
        this.pdfTop = val[0].zone.position.y * 100;
        this.endPosition = val[0].zone.drag.end;
        this.previousPosition = val[0].zone.drag.end;
        this.startPosition = val[0].zone.drag.start;
      },
      deep: true,
    },
  },
  props: {
    zones: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      required: false,
    },
    scale: {
      type: Number,
      default: 1,
      required: true,
    },
    viewport: {
      type: Object,
      required: true,
    },
  },
  computed: {
    actualSizeViewport() {
      return this.viewport.clone({scale: this.scale});
    },
    styleObject() {
      return {
        top: `calc(${this.absoluteTop}% - ${this.height}px)`,
        height: typeof this.height === 'number' ? `${this.height}px` : this.height,
      };
    },
    iconStyleObject() {
      return {
        height: `${this.height / 1.5}px`,
        width: `${this.height / 1.5}px`,
      };
    },
    absoluteTop() {
      return 100 - this.pdfTop;
    },
    paraphZones() {
      return this.zones.filter(item => item.zone.zoneType === 'paraph');
    },
  },
  methods: {
    setSize() {
      this.height = 25 * this.scale;
    },
    getInitials(fullName) {
      return initials(fullName, '');
    },
  },
  mounted() {
    this.pdfTop = this.zones[0].zone.position.y * 100;
    this.setSize();
  },
};
</script>
